<template>
    <b-card no-body>
        <b-card-body class="pb-0">
            <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                    {{ statistic }}
                </h2>
                <span>{{ statisticTitle }}</span>
            </div>
        </b-card-body>
        <!-- {{ chartData }} -->
        <vue-apex-charts
            type="area"
            height="100"
            width="100%"
            :options="chartOptionsComputed"
            :series="chartData.series"
        />
    </b-card>
</template>

<script>
import { BCard, BCardBody } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { areaChartOptions } from "./chartOptions";

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardBody,
    },
    props: {
        icon: {
            type: String,
            default: () => "user",
        },
        statistic: {
            type: Number,
        },
        statisticTitle: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "warning",
        },
        chartData: {
            type: Array,
            default: () => [],
        },
        chartOptions: {
            type: Object,
            default: null,
        },
    },
    computed: {
        chartOptionsComputed() {
            if (this.chartOptions === null) {
                const options = JSON.parse(JSON.stringify(areaChartOptions));
                options.theme.monochrome.color = $themeColors[this.color];
                return options;
            }
            return this.chartOptions;
        },
    },
};
</script>
