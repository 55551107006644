<template>
    <b-card v-if="data" class="earnings-card">
        <b-row>
            <b-col cols="6">
                <b-card-title class="mb-1">
                    {{ title }}
                </b-card-title>
                <div class="font-small-2">هذا الشهر</div>
                <h5 class="mb-1">
                    {{ thisMonthCode }}
                </h5>
                <b-card-text class="text-muted font-small-2">
                    <span class="font-weight-bolder">{{ lastMonthCount }}</span><span> الشهر الماضي</span>
                </b-card-text>
            </b-col>
            <b-col cols="6">
                <!-- chart -->

                <!-- :options="{...earningsChart.chartOptions }" -->
                <vue-apex-charts height="120" :options=" chartOptions" :series="[lastMonthCount, thisMonthCode]" />

            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
        VueApexCharts,
    },
    props: {
        data: {
            type: Object,
            default: () => { },
        },
        title: {
            type: String,
            default: () => { },
        },
        lastMonthCount: {
            type: Number,
            default: 0,
        },
        thisMonthCode: {
            type: Number,
            default: 0,
        },
        currentMonthPrice: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            earningsChart: {
                chartOptions: {

                },
            },
         };
    },

    computed: {
        chartOptions() {
            const vm = this
            return {

                chart: {
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: { show: false },
                comparedResult: [2,  8],
                labels: ["الشهر الحالي", "الشهر السابق"],
                stroke: { width: 0 },
                colors: [
                    $earningsStrokeColor2,
                    $earningsStrokeColor3,
                    $themeColors.success,
                ],
                grid: {
                    padding: {
                        right: -20,
                        bottom: -8,
                        left: -20,
                    },
                },
                plotOptions: {
                    pie: {
                        startAngle: -10,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    offsetY: 15,
                                },
                                value: {
                                    offsetY: -15,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}%`;
                                    },
                                },
                                total: {
                                    show: true,
                                    offsetY: 15,
                                    label: "%",
                                    formatter() {
                                        return (vm.thisMonthCode / vm.lastMonthCount | 0) ;
                                    },
                                },
                            },
                        },
                    },
                },
                responsive: [
                    {
                        breakpoint: 1325,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                    {
                        breakpoint: 1045,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                ],
            }
        }

    }
};
</script>
